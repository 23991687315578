import {Menu} from '@headlessui/react';
import {usePage} from '@inertiajs/inertia-react';
import {mdiGhostOff} from '@mdi/js';
import {Icon} from '@mdi/react';
import MenuItem from 'Components/MenuItem';
import MenuItemsWrapper from 'Components/MenuItemsWrapper';
import * as React from 'react';
import {useState} from 'react';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';

const AccountMenu = ({routePrefix, hideSettings}) => {
  const withPrefix = route => routePrefix ? `${routePrefix}.${route}` : route;
  const {avatar, email, id, name, role, is_ghosting} = useUser();
  const {application: {demo_mode}} = usePage().props;
  const [trigger, setTriggerElement] = useState(null);

  return (
    <div className="flex items-center justify-center" id="accountMenu">
      <div className="relative inline-block text-left">
        <Menu>
          {({open}) => (
            <>
              <span className="rounded-lg shadow">
                <Menu.Button
                  ref={setTriggerElement}
                  className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring">
                  <span className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring" id="user-menu"
                        aria-label="User menu" aria-haspopup="true">
                    <img className="h-8 w-8 rounded-full" src={avatar} alt={`${name} avatar`}/>
                  </span>
                </Menu.Button>
              </span>

              <MenuItemsWrapper show={open} trigger={trigger} width="w-48">
                <div className="px-4 py-3">
                  <p className="text-xs leading-5 font-bold text-gray-500">Signed in as</p>
                  <p className="text-sm font-medium leading-5 text-gray-900 truncate">
                    {email}
                    {role &&
                      <>
                        <br/>
                        ({role?.label})
                      </>
                    }
                  </p>
                </div>

                {(!hideSettings && !demo_mode) && <MenuItem href={route(withPrefix('users.edit'), id)}>Account settings</MenuItem>}
                <MenuItem href={route(withPrefix('logout'))}>Sign out</MenuItem>
                {is_ghosting && (
                  <MenuItem href={route(withPrefix('ghost.user.stop'))}>
                    <div className="flex gap-2 items-center">
                      <Icon path={mdiGhostOff} size={.8}/>
                      Stop Ghosting
                    </div>
                  </MenuItem>
                )}
              </MenuItemsWrapper>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
};

export default AccountMenu;
